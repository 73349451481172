import React from 'react'
import Layout from '../../../components/layout'

const DeployToNetlify = () => {

  return (
    <Layout>
      <div>
        <h1>This is the new Deploy To Netlify article</h1>
      </div>
    </Layout>
  )
}

export default DeployToNetlify